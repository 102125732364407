















import Vue from "vue";
import Me from "@/components/Me.vue";
import History from "@/components/History.vue";

export default Vue.extend({
  name: "App",

  components: {
    Me,
    History,
  },

  data: () => ({
    //
  }),
});
